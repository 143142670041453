import { SVGProps } from 'react';

const EmailIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      width='100%'
      height='100%'
      {...props}
    >
      <g clipPath='url(#a)'>
        <path
          stroke='#000'
          strokeLinejoin='round'
          d='M3 17.5v-11a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1Z'
        />
        <path stroke='#000' strokeLinejoin='round' d='m3 6 9 6 9-6' />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmailIcon;
