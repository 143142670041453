export const SITEMAP = {
  DASHBOARD: '/',
  REGISTER: '/auth/register',
  LOGIN: '/auth/login',
  PENDING_APPROVAL: '/auth/pending-approval',
  MARKETING: '/marketing',
  MARKETING_MATERIAL: (uid: string) => '/marketing/material/' + uid,
  SDM: '/sd-marketing',
  COMPANY: '/eyeforce',
  COOP: '/co-op',
  REWARD_DETAILS: (uid: string) => '/co-op/reward/' + uid,
  ARTICLES: '/articles',
  ARTICLE: (uid: string) => `/articles/article/${uid}`,
  ADMIN: '/admin',
  SETTINGS: '/settings',
  SETTINGS_WIDGET: '/settings/widget',
  SETTINGS_EMAIL: '/settings/email',
  SETTINGS_PROFILE: '/settings/profile',
  PROFILE: '/profile',
  VIEW_CONTEST: (uid: string) => `/contest/${uid}`,
  CONTACT: '/contact',

  ADMIN_PAGES: {
    ADMINS: {
      INDEX: '/admin/admins',
    },
    DEALERS: {
      INDEX: '/admin/dealers',
      ALL: '/admin/dealers/all',
      SALES: '/admin/dealers/sales',
      REQUESTS: '/admin/dealers/requests',
      DEACTIVATED: '/admin/dealers/deactivated',
      DELETED: '/admin/dealers/deleted',
    },
  },
};
