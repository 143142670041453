import { SVGProps } from 'react';

const QuestionIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 7 13'
      width='100%'
      height='100%'
      {...props}
    >
      <path
        fill='#000'
        d='M2.416 9.168a.899.899 0 0 1-.016-.176v-.176c0-.448.064-.843.192-1.184.138-.341.304-.65.496-.928.202-.288.416-.555.64-.8.234-.245.448-.49.64-.736.202-.256.368-.523.496-.8.138-.288.208-.613.208-.976 0-.49-.144-.89-.432-1.2-.278-.31-.747-.464-1.408-.464-.384 0-.774.07-1.168.208A4.556 4.556 0 0 0 1.04 2.4l-.432-.992a5.743 5.743 0 0 1 1.28-.576C2.336.682 2.89.608 3.552.608c.469 0 .88.07 1.232.208.362.128.661.31.896.544.245.235.426.512.544.832.117.32.176.661.176 1.024 0 .48-.075.901-.224 1.264a4.16 4.16 0 0 1-.544.976c-.214.288-.448.56-.704.816-.246.256-.48.528-.704.816A4.583 4.583 0 0 0 3.68 8c-.139.33-.208.72-.208 1.168H2.416Zm-.32 2.096c0-.288.08-.512.24-.672.17-.17.394-.256.672-.256.288 0 .517.085.688.256.17.16.256.384.256.672 0 .277-.086.501-.256.672-.171.17-.4.256-.688.256-.278 0-.502-.085-.672-.256-.16-.17-.24-.395-.24-.672Z'
      />
    </svg>
  );
};

export default QuestionIcon;
